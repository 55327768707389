.topic__contact{
   box-shadow: 0 8px 24px 0 rgba( 31, 38, 135, 0.37 );
   margin-bottom: 0 !important;
 }
 
 .contact{
   display: flex;
   width: 100%;
   flex-wrap: wrap;
   padding: 1rem 2rem;
   align-items: center;
   background-color: var(--branco);
 }
 
 .contact__logo{
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
 }
 
 .contact__logo img{
   min-width: 20%;
   max-width: 20%;
   margin-right: 1rem;
 }
 
 .contact__logo p{
   margin: 0;
 }
 
 .contact__address{
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   width: 100%;
   margin-top: 1rem;
   align-items: center;
 }
 
 .contact__address p{
   display:flex;
   align-items: center;
   margin-top: 0.25rem;
   margin-bottom: 0.5rem;
   font-size: 1.25rem;
 }
 
 .contact__address p > svg {
   font-size: 1.5rem;
   margin-right: 1rem;
 }
 
 .contact__address p+p{
   font-size: 0.8rem;
 }
 
 .contact__by{
   width: 100%;
   text-align: center;
   background-color: var(--cor-500);
   padding: 1rem 0 1rem 0;
   color: var(--branco);
 }

/* DESKTOP CSS */
@media screen and (min-width:768px)
{
   .contact{
   flex-wrap: nowrap;
   }

   .contact__address p+p{
   font-size: 1rem;
   }
}