.pageMenu{
   padding:1rem;
   padding-top: 2rem;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
}

.pageMenu__search{
   width: 100%;
   display: flex;
   align-items: center;
   background-color: var(--branco);
}

.pageMenu__search input{
   width: calc(100% - 2rem);
   background:none;
   border: none;
   padding: .5rem 0 .5rem .75rem;
   font-size: 1.25rem;
   border: 1px solid var(--cor-400-a);
}

.pageMenu__search svg{
   font-size: 2.5rem;
   justify-self: flex-end;
}


.pageMenu__categorie{
   border:1px solid var(--cor-800);
   margin-top: 1.5rem;
}

.pageMenu__categorie_title{
   width: 100%;
   padding:0.75rem 1rem;
   background-color: var(--cor-800);
   margin: 0;
   color: var(--branco);
}

.pageMenu__categorie_block{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding:1rem;
   row-gap: 1rem;
   column-gap:1rem;
}

.pageMenu__categorie_block a{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding:1.5rem 0.5rem;
   outline: 2px solid var(--cor-800);
   text-transform: uppercase;
   text-decoration: none;
   color: var(--cor-800);
   font-weight: 600;
   text-align: center;
}

.pageMenu__categorie_block a:hover{
   outline: 3px solid var(--cor-800);
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{

   .pageMenu__search{
      margin-left: auto;
      width: calc(100% / 3);
   }

   .pageMenu__search input{
      font-size: 1rem;
   }

   .pageMenu__categorie_block a{
      --num_colums: 4;
      width: calc(100% / var(--num_colums) - 1rem * (var(--num_colums) - 1) / var(--num_colums));
   }

}