.alertGlass__shadow
{
   position: fixed;
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;
   top: 0;
   left: 0;
   background-color: hsla(190, 91%, 9%, 0.63);
   backdrop-filter: blur(3px);
   cursor: progress;
   z-index: 9999 !important;
}

.alertGlass{
   display: flex;
   width: calc(100% - 1rem);
   justify-content: center;
   flex-direction: column;
   background: linear-gradient(180deg, rgba(245, 245, 245, 0.493) 0%, rgba(255, 255, 255, 0.356) 100%);
   background-color: var(--branco);
   padding: 1rem 2rem;
   border-radius: 2rem;
   color: var(--preto);
   font-size: 1.25rem;
   box-shadow: 0px 0px 20px 0px rgba(22, 128, 189, 0.247);
}

.alert_frase{
  display: block;
  text-align: center;
  margin-bottom: 2rem !important;
  font-size: 1.5rem;
}

.alert_button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.alert_button button{
  align-self: center;
  border: 2px solid var(--cor-300);
  box-shadow: 0 1px 2px 0 #000;
  color: var(--cor-300);
  padding: .5rem 1rem;
  text-decoration: none;
  background-color: var(--preto);
  /* transform: scaleX(1.25); */
  transition: box-shadow .2s ease;
  font-size: 1.25rem;
  font-weight: 800;
  cursor: pointer;
}

.alert__icon{
  font-size: 6rem;
  margin-bottom: 1rem;
}


@media only screen and (min-width: 768px){

  .alertGlass{
    width: 50%;
  }

  .alert_body{
    width: 90%;
  }

}