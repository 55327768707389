:root{
   --primary-gradient: linear-gradient(33deg, rgba(5, 43, 255, 0.658) 0%, rgb(8, 205, 231) 100%);
   --secundary-gradient: linear-gradient(33deg,  rgba(73, 0, 95, 0.966) 0%, rgba(235, 31, 116, 0.705) 100%);
   --success-gradient: linear-gradient(33deg, rgba(0, 138, 34, 0.829) 0%, rgba(6, 248, 147, 0.959) 100%);
   --info-gradient: linear-gradient(33deg,  rgba(75, 75, 75, 0.486) 0%, rgba(94, 92, 92, 0.87) 100%);
   --error-gradient: linear-gradient(33deg, rgba(255, 5, 5, 0.89) 0%, rgba(233, 41, 41, 0.836) 100%);
   --alert-gradient: linear-gradient(33deg, rgba(255, 55, 5, 0.726) 0%, rgba(255, 197, 5, 0.651) 100%);

   --glass_shadow: hsla(202, 79%, 41%, 0.247);
   --glass_bg_gradient: linear-gradient(180deg, rgba(245, 245, 245, 0.493) 0%, rgba(255, 255, 255, 0.356) 100%);
   --glass_blue: rgb(2, 92, 177);
   --glass_gray: hsla(0, 0%, 0%, 0.25);
}

.glasspage h1,
.glasspage h2,
.glasspage h3,
.glasspage h4,
.glasspage p
{
   margin: 0px;
}

.glasspage{
   padding: 1rem;
   background: var(--glass_bg_gradient);
   border-radius: 2rem;
   box-shadow: 0px 0px 10px 0px var(--glass_shadow);
}

.retaguarda_title{
   align-items: center;
   padding: 0.8rem 1.75rem;
   border-radius: 2rem;
   background: var(--secundary-gradient);
   color: var(--branco);
   font-weight: 800;
   width: fit-content;
   margin-bottom: 2rem !important;
}

.retaguarda_formulario{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   row-gap: 1rem;
}

.celula_buttons{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}

.celula_buttons button{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: fit-content;
   cursor: pointer;
   border-radius: 2rem;
   align-self: flex-end;
}

.celula_buttons.end{
   justify-content: flex-end;
}

.celula_buttons.center{
   justify-content: center;
}

.celula_buttons.start{
   justify-content: flex-start;
}

.celula_buttons .primary{
   background: var(--primary-gradient);
   color:var(--branco);
   font-weight: 600;
   border: none;
}

.celula_buttons .success{
   background: var(--success-gradient);
   color:var(--branco);
   font-weight: 800;
   border:none;
}

.celula_buttons .error{
   background: var(--error-gradient);
   color:var(--branco);
   font-weight: 800;
   border:none;
}

.celula_buttons .sm{
   padding: .5rem 1rem;
   font-size: 1rem;
}

.celula_buttons .md{
   padding: .75rem 1.5rem;
   font-size: 1.115rem;
}

.celula_buttons .lg{
   padding: 1rem 1.5rem;
   font-size: 1.5rem;
}

.celula_buttons .primary,
.celula_buttons .error,
.celula_buttons .success{
   transition: transform 0.1s ease-out;
}

.celula_buttons .primary:hover,
.celula_buttons .error:hover,
.celula_buttons .success:hover{
   background-color: var(--preto);
   color: var(--branco);
}

.celula_buttons .primary:focus,
.celula_buttons .error:focus,
.celula_buttons .success:focus{
   background-color: var(--preto);
   outline-offset: 2px;
   outline: 2px solid var(--glass_shadow);
}

.celula_buttons .primary:active,
.celula_buttons .error:active,
.celula_buttons .success:active{
   transform: scale(0.96);
}

.align_title{
   margin-top: 2rem;
}


/* INSCRICAO */

.form_class{
   display: flex;
   width: 100%;
   justify-content: center;
   flex-direction: column;
}

.required{
   color: red;
}

.celula_form_row{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   row-gap: 0.5rem;
   column-gap: 0.5rem;
   margin-bottom: 1rem;
}

.celula_form{
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.celula_form label{
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   padding-inline: 1rem;
   font-size: 1.115rem;
   font-weight: 400;
}

.celula_form__label_required{
   font-size: .75rem;
   background: var(--alert-gradient);
   background-color: rgb(214, 214, 214);
   color: var(--branco);
   font-weight: 800;
   padding: .25rem 0.75rem;
   border-radius: 2rem;
}

.celula_form input,
.celula_form select{
   padding: 0.5rem 1rem;
   font-size: 1rem;
   border: 1px solid var(--preto);
   border-radius: 2rem;
   outline: 0px;
   background: transparent;
   transition: box-shadow 200ms ease-in-out;
}

.celula_form select:focus,
.celula_form input:focus{
   outline: none;
   border: 1px solid var(--glass_blue);
   outline-offset: 2px;
   outline: 2px solid var(--glass_shadow);
}

.celula_form select{
   background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
   background-position: calc(100% - 0.75rem) center !important;
   -moz-appearance:none !important;
   -webkit-appearance: none !important; 
   appearance: none !important;
   padding-right: 2rem !important;
}

.celula_form_error select,
.celula_form_error input{
   outline: 3px solid red;
   outline-offset: -2px;
}

.celula_end{
   justify-self: end;
   align-self: flex-end;
}

.celula_middle{
   align-self: center;
   justify-self: center;
}

.celula_5,
.celula_10,
.celula_15,
.celula_20,
.celula_25,
.celula_30,
.celula_40,
.celula_45,
.celula_50,
.celula_60,
.celula_70,
.celula_80,
.celula_90,
.celula_100{
   width: 100%;
}

.text-email{
   text-transform: none !important;
}

.celula_error{
   padding:0rem 1rem;
   color: red;
   text-align: right;
}

.celula_input_btn{
   position: relative;
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
}

.celula_input_btn input{
  width: 100%;
}

.celula_input_btn button{
   right: 0;
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 30%;
   height: 100%;
   background-color: white;
}

.button_busca_pop{
   font-size: 1.35rem;
   background: var(--primary-gradient);
   color:var(--branco);
   font-weight: 600;
   border-radius: 2rem;
   border: none;
   cursor: pointer;
}

.button_busca_pop:hover{
   background-color: var(--preto);
}

.button_busca_pop:focus{
   background-color: var(--preto);
   outline-offset: 2px;
   outline: 2px solid var(--glass_shadow);
}

.celula__text_editor .ql-toolbar{
   position: sticky;
   top:0;
   display: flex;
   background-color: var(--branco);
   border-color: var(--preto) !important;
   border-radius: 0rem 0rem 0 0;
   z-index: 1000;
}

.celula__text_editor .ql-container{
   display: flex;
   min-height: 150px;
   height: fit-content;
   border-color: var(--preto) !important;
   border-radius: 0 0 1.5rem 1.5rem;
   resize: vertical;
   overflow: visible;
}

.celula__text_editor .ql-editor{
   width: 100%;
   height: fit-content;
   overflow: visible;
}

.celula__text_editor .ql-tooltip{
   z-index: 99999;
}

@media only screen and (min-width: 768px)
{
   .retaguarda_formulario{
      padding-left: 1rem;
   }

   .celula_5{
      width: 5%;
   }
   
   .celula_10{
      width: 10%;
   }
   
   .celula_15{
      width: 15%;
   }
   
   .celula_20{
      width: 20%;
   }
   
   .celula_25{
      width: 25%;
   }
   
   .celula_30{
      width: 30%;
   }
   
   .celula_40{
      width: 40%;
   }
   
   .celula_45{
      width: 45%;
   }
   
   .celula_50{
      width: 50%;
   }
   
   .celula_60{
      width: 60%;
   }
   
   .celula_70{
      width: 70%;
   }
   
   .celula_80{
      width: 80%;
   }
   
   .celula_90{
      width: 90%;
   }
   
   .celula_100{
      width: 100%;
   }   

   .celula_5,
   .celula_10,
   .celula_15,
   .celula_20,
   .celula_25,
   .celula_30,
   .celula_40,
   .celula_45,
   .celula_50,
   .celula_60,
   .celula_70,
   .celula_80,
   .celula_90,
   .celula_100{
      margin-right: 1rem;
   }
}