.menuCategories{
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: center;
}

.menuCategories__form{
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   margin-bottom: 2rem;
}

.menuCategories__list{
   display: flex;
   width: 100%;
   flex-direction: column;
   row-gap: .5rem;
}

.menuCategories__in{
   width: 100%;
   display: flex;
   flex-direction: row;
   outline: 1px solid hsla(0, 0%, 47%, 0.568);
   align-items: center;
   padding: .5rem 1rem;
   font-size: 1rem;
   justify-content: space-between;
   border-radius: 2rem;
}