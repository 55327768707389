.fileUploader{
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.fileUploader__title{

}

.fileUploader__dropzone{
   width: 100%;
   text-align: center;
   font-size: 1.25rem;
   padding:1rem;
   border: 2px dashed hsla(0, 0%, 0%, 0.25);
   background-color: hsla(0, 0%, 0%, 0.05);
   border-radius: 1rem;
   margin-bottom: 1rem;
   transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.fileUploader__dropzone_active{
   border: 2px dashed var(--glass_blue);
   box-shadow: 0px 0px 15px 0px var(--glass_shadow);
   background-color: var(--glass_shadow);
   color: var(--glass_blue);
}

.fileUploader__list{
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   border: 1px solid hsla(212, 100%, 50%, 0.082);
   padding: 2rem .5rem;
   padding-top: 1rem;
   border-radius: 2rem;
   row-gap: 1.5rem;
}

.fileUploads__options{
   display: flex;
   width: 100%;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   margin-bottom: 1rem;
   border-radius: 2rem;
}

.fileUploads__options span{
   font-size: 1.125rem;
   padding:0 1rem;
}

.fileUploads__switcher{
   position: relative;
   display: flex;
   align-items: center;
   border:2px solid #40aef8;
   padding:1rem 2rem;
   border-radius: 2rem;
   background-color: transparent;
   cursor: pointer;
}

.fileUploads__switcher:hover{
   /* background-color: transparent; */
}

.fileUploads__switcher:before{
   content: "";
   position: absolute;
   background:var(--primary-gradient);
   width: 1.75rem;
   aspect-ratio: 1;
   border-radius: 100%;
   left: 0.125rem;
   transition: left 150ms ease;
}

.fileUploads__switcher[aria-checked="true"]:before{
   left: calc(2.125rem);
}

.fileUploads__file,
.fileUploads__img{
   display: flex;
   width: 100%;
   flex-direction: column;
   flex-wrap: wrap;
   row-gap: 1rem;
   outline: 1px solid hsla(0, 0%, 0%, 0.25);
   padding: .5rem;
   border-radius: 1rem;
}

.fileUploads__img img{
   width: 50%;
   align-self: center;
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{

   .fileUploads__file,
   .fileUploads__img{
      flex-direction: row;
   }

   .fileUploads__img img{
      width: auto;
      max-width: 10%;
      height: 4.1625rem;
      margin-right: 1rem;
      border-radius: 1rem;
      margin-top: auto;
      margin-bottom: auto;
   }
}