.errorHandler{
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
}

.errorHandler__body{
  width: 75%;
  padding: 1.5rem;
  padding-top: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: var(--cor-400-a);
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px var(--preto-a);
  outline: 2px solid var(--cor-400-a);
  text-align: center;
}

.errorHandler__body img{
  display: block;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.errorHandler__body h2{
  background-color: var(--cor-400);
  color: var(--branco);
  padding:1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.errorHandler__body button{
  background-color: var(--cor-400);
  border:none;
  color: var(--branco);
  outline: 2px solid var(--branco);
  padding:0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.errorHandler__body button:hover{
  background-color: var(--branco-a);
}

.errorHandler__log{
  --button_size: 4rem;

  height: var(--button_size);
  overflow: hidden;
  background-color: var(--branco);
  text-align: initial;
}

.errorHandler__log[aria-expanded="true"] svg{
  transform: rotateX(180deg);
}

.errorHandler__log[aria-expanded="true"]{
  height: fit-content;
  color: red;
}

.errorHandler__log button{
  border: none;
  width: 100%;
  height: var(--button_size);
  font-size: 1rem;
  background-color: var(--preto-a);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--preto);
}

.errorHandler__log textarea{
  width: 100%;
  border:none;
  resize: none;
  background: none;
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{

  .errorHandler__log{
    --button_size: 2.5rem;
  }

  .errorHandler__body h2{
    flex-direction: row;
  }



}