.retaguarda__container{
   display: flex;
   width: 100%;
   min-height: 100vh;
   flex-direction: row;
   flex-wrap: wrap;
   flex-shrink: 0;
}

.retaguarda__page{
   width: 100%;
   margin-top: var(--menu__mobile_heigth);
   padding:1.5rem;
   background-color: var(--branco);
}

@media screen and (min-width:768px)
{
   .retaguarda__container{
      flex-wrap: nowrap;
   }

   .retaguarda__page{
      width: calc(100% - 200px);
      margin-top: 0;
   }
}