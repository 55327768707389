.pageFiles{
   padding:1rem;
   padding-top: 2rem;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
}

.pageFiles__title{
   display: flex;
   flex-direction: column;
   align-items: center;
   color: var(--cor-800);
   margin-top:2rem;
   margin-bottom: 2rem;
   row-gap: 1rem;
}

.pageFiles__title h2{
   margin: 0;
   font-size: 1.5rem;
}

.pageFiles__title > h2 > svg{
   font-size: 3rem;
   margin-bottom: -3px;
}

.pageFiles__search{
   width: 100%;
   display: flex;
   align-items: center;
   background-color: var(--branco);
}

.pageFiles__search input{
   width: calc(100% - 2rem);
   background:none;
   border: none;
   padding: .5rem 0 .5rem .75rem;
   font-size: 1.25rem;
   border: 1px solid var(--cor-400-a);
}

.pageFiles__search svg{
   font-size: 2.5rem;
   justify-self: flex-end;
}


.pageFiles__block{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   border:1px solid var(--cor-400-a);
   border-bottom: none;
   padding: 1.25rem 0;
}

.pageFiles__block:last-child{
   border:1px solid var(--cor-400-a);
}

.pageFiles__block:hover{
   background-color: var(--cor-400-a);
}

.pageFiles__block a{
   display: flex;
   text-decoration: none;
   justify-content: center;
   align-items: center;
}

.pageFiles__block_info{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   row-gap: 0.5rem;
   width: 50%;
   padding:1rem;
   text-align: left;
   font-size: 1.1rem;
}

.pageFiles__block_info span{
   font-size: .75rem;
}

.pageFiles__block_download{
   width: 50%;
   font-size: 1.1rem;
   column-gap: .5rem;
}

.pageFiles__block_download svg{
   font-size: 2rem;
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{

   .pageFiles__title{
      flex-direction: row;
      justify-content: space-between;
   }

   .pageFiles__search{
      width: calc(100% / 3);
   }

   .pageFiles__search input{
      font-size: 1rem;
   }

   .pageFiles__block_info{
      padding: 0;
      width: calc(100% / 3 * 2);
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
   }

   .pageFiles__block_info span{
      font-size: 1rem;
   }

   .pageFiles__block_download{
      width: calc(100% / 3 * 1);
   }

   .pageFiles__block{
      padding: .75rem 0;
   }

}