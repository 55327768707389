.app__container{
   margin-top: calc(55px + 1rem);
}

.page__container{
   display: flex;
   width: 100%;
   min-height: 100vh;
   flex-direction: column;
   padding-top: 4.8rem;
   color: var(--preto);
}

.page__margin_top_menu{
   padding-top: 4.8rem;
}

.page__title{
   text-align: center;
   width: 100% !important;
}

.page__sub_title{
   text-align: center;
   width: 100% !important;
   margin-top: 0px;
   margin-bottom: 3rem;
}