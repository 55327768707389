.menu_body{
   outline-offset: -2px;
   display:flex;
   flex-direction: column;
   width: 200px;
   height: 100vh;
   overflow-y: auto;
   position: fixed;
   padding:1.5rem 0rem;
   background-color: var(--branco);
   color: var(--preto);
   box-shadow: 1px 0px 10px 0px var(--glass_shadow);
   transition: 0.3s ease-out;
   left: 0px;
   z-index: 5000;
}

.menu__header{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   text-align: center;
   font-weight: 600;
}

.menu__header_logo{
   width: 100px;
}

.menu_item{
   display:flex;
   flex-direction: column;
}

.menu_item_title{
   display: flex;
   justify-content: space-around;
   align-items: center;
   padding:0.5rem 1rem;
   cursor: pointer;
   transition: background-color 0.2s ease-out;
   color: var(--glass_blue);
   text-decoration: none;
   z-index: 100;
   user-select: none;
}

.menu_item_title:hover{
   background-color: var(--glass_blue);
   color: var(--branco);
   transition: background-color 0.2s ease;
}

.menu_item_title_indicator{
   transform: rotate(0deg);
   transition: transform 0.2s ease-out;
}

.menu_item_title span{
   width: 110px;
}

.menu_item_title_not_sub{
   display: block;
   width: 1rem !important;
   height: 1rem !important;
}

.menu_item_title_indicator.turned{
   transform: rotate(180deg);
   transition: transform 0.2s cubic-bezier(0.23, 1, 0.320, 1);
}

.menu_item_title::after{
   content: "";
   display: block;
   position: absolute;
   background-color: red;
}

.menu_sub{
   display: grid;
   transition: grid-template-rows 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
   grid-template-rows: 0fr;
}

.menu_sub > div{
   overflow: hidden;
}

.menu_sub[aria-expanded="true"] {
   grid-template-rows: 1fr;
   box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.menu__sub_container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: .5rem;
}

.menu_sub_item{
   outline: 1px solid var(--glass_gray);
   border-radius: 2rem;
   color: var(--preto);
   margin-left: 1rem;
   margin-right: 0.5rem;
   padding-top:0.5rem;
   padding-bottom:0.5rem;
   padding-left: 1rem;
   cursor: pointer;
   z-index: 50;
   text-decoration: none;
   transition: outline 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menu_sub_item:first-of-type{
   margin-top: .5rem;
}

.menu_sub_item:last-of-type{
   margin-bottom:.5rem;
}

.menu_sub_item:hover{
   outline: 1px solid var(--glass_blue);
   color: var(--glass_blue);
}

.retaguarda_menu_sombra{
   display: block;
   width: 200px;
   min-height: 100%;
}


/* MOBILE */

.menu_body_mobile_closed{
   left:-200px;
   transition: 0.2s ease-in;
}

:root{
   --menu__mobile_heigth: 3rem;
}

.retaguarda_menu_sombra_closed{
   position: fixed;
   display: flex;
   width: 100%;
   height: var(--menu__mobile_heigth);
   padding-inline: 1rem;
   justify-content: space-between;
   align-items: center;
   font-size: 1rem;
   background-color: var(--branco);
   color: var(--preto);
   box-shadow: 0px 1px 10px 0px var(--glass_shadow);
   z-index: 1000;
}

.retaguarda_menu_sombra_closed svg{
   font-size: 1.5rem;
}

.close_menu_mobile{
   position: absolute;
   left:180px;
   top:0;
}