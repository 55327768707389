.logo-reserva-menu-top
{
   width: 50px;
   filter: drop-shadow(0px 0px 1px var(--branco));
}

.menu-fixed-container
{
   top:0;
   display:flex;
   width: 100%;
   height: 4.8rem;
   position: fixed;
   justify-content: space-between;
   align-items: center;
   background-color: var(--cor-500);
   padding:0.5rem;
   padding-left:10%;
   padding-right:10%;
   box-shadow: 0px 1px 2px 0px var(--preto);
   z-index: 1000;
}

.menu-fixed-links-container-mobile{
   top:0;
   left:0;
   position: absolute;
   background: var(--cor-800);
   background: linear-gradient(212deg, var(--cor-500) 0%, var(--cor-800) 70%);
   display:flex;
   width: 60%;
   height: 100vh;
   justify-content: flex-start;
   flex-direction: column;
   padding-left: 1.5rem;
   padding-right: 1.5rem;
   transition: 250ms ease-out;
   box-shadow: 1px 0px 10px var(--preto);
}

.menu-mobile-closed{
   left:-60%;
   transition: 250ms ease-in;
   box-shadow: 0px 0px 0px var(--preto);
}

.button-mobile{
   font-size: 1.75rem;
   color: var(--cor-500);
   background-color: var(--branco);
   display: flex;
   align-items: center;
   text-align: center;
   padding:0.25rem;
   border:0px;
   border-radius: 4px;
   transition: 250ms ease-out;
}

.button-mobile-active{
   background-color: var(--preto);
   color: var(--branco);
   border-radius: 4px;
   transition: 250ms ease-in;
}


.menu-fixed-links-container{
   display: flex;
   width:75%;
   justify-content: flex-end;
   column-gap: 2rem;
   align-items: center;
}

.menu-fixed-link{
   color: var(--branco);
   font-size: 1rem;
   text-decoration: none;
   font-weight: 600;
   padding-top:2px;
   line-height: 3rem;
}

.menu-fixed-link-mobile{
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   color:var(--branco);
   margin-bottom: 1rem;
   line-height: normal;
   padding: 0.5rem 0rem;
}

.menu-fixed-link::after{
   content: "";
   background-color: var(--branco);
   width: 100%;
   transform: scaleX(0);
   height: 2px;
   display: block;
   margin:auto;
   transition: transform 100ms ease-in;
}

.menu-fixed-link:hover::after{
   transform: scaleX(1);
   transition: transform 150ms ease-out;
}

.menu-fixed-link-mobile::before{
   content: "";
   align-content: center;
   width: 2px;
   height:100%;
   background-color: var(--branco);
}

.menu-fixed-link-mobile::after{
   content: "";
   display: block;
   width: 90%;
   margin: 0;
   margin-top:0.25rem;
   height: 0px;
   background-color: var(--branco);
}

.menu__logo_title{
   display: flex;
   flex-direction: column;
   margin-left:1rem;
}

.menu__logo_title p{
   margin:0;
   font-size: 1rem;
   font-weight: 600;
   color: var(--branco);
   white-space: nowrap;
   margin-bottom: .25rem;
}