.carousel{
  display: flex;
  width: 100%;
  height: calc(100vh - 4.8rem);
  padding: 2rem !important;
}

.slide{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-position: 0 -20px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2rem;
  justify-content: center;
  box-shadow: 0 8px 24px 0 rgba( 31, 38, 135, 0.37 );
  overflow: hidden;
}

.slide__text_container{
  display: flex;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  justify-content: baseline;
  align-self: flex-end;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  color: var(--branco);
  text-align: left;
  /* outline: 1px solid yellow; */
}

.slide__text_right{
  align-items: flex-end;
  text-align: right;
}

.slide__title{
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  hyphens: auto;
  /* outline: 1px solid green; */
}

.slide__description{
  font-size: 1.25rem;
  text-align: justify !important ;
  font-weight: 400;
  /* outline: 1px solid blue; */
  hyphens: auto;
}

.slide__description a{
  color: var(--branco);
}

.slide__img1{
  background-image: url("/src/assets/imgs/homeSlider/slide1.jpg");
  background-size: cover;
  background-position: center;
}

.slide__img2{
  background-image: url("/src/assets/imgs/homeSlider/slide2.jpg");
  background-position: center;
  background-size: cover;
}

.slide__img3{
  background-image: url("/src/assets/imgs/homeSlider/slide3.jpg");
  background-size: cover;
  background-position: center;
}

.slide__text_background1{
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, hsla(219, 75%, 36%, 0.5) 100%);
}

.slide__text_background2{
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, hsla(138, 75%, 36%, 0.5) 100%);
}

.slide__text_background3{
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, hsla(138, 75%, 36%, 0.5) 100%);
}

.topic{
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin-bottom: 3rem;
}

.topic__title_parallax{
  display: block;
  width: 100%;
  padding:2rem 1rem;
  text-align: center;
  font-size: 2rem;
  color: var(--branco);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

.title_img1{
  padding-top:4rem;
  padding-bottom: 3rem;
  background-image: linear-gradient(hsla(140, 100%, 12%, 0.5), hsla(140, 100%, 12%, 0.25)), url("/src/assets/imgs/homeSlider/slide1.jpg");
}

.title_img2{
  padding-top:4rem;
  padding-bottom: 3rem;
  background-image: linear-gradient(hsla(222, 100%, 33%, 0.75), hsla(222, 100%, 33%, 0.5)), url("/src/assets/imgs/home/icms_ecologico.jpg");
}

.title_img3{
  padding-top:4rem;
  padding-bottom: 3rem;
  background-image: linear-gradient(hsla(140, 100%, 12%, 0.75),hsla(140, 100%, 12%, 0.5)), url("/src/assets/imgs/home/localizacao.jpg");
}

.topic__text{
  padding-top: 0.05px;
  padding-inline: 2em;
  columns: 250px 2;
  column-gap: 2rem;
  margin-top: 2rem;
}

.topic__text_dont_break{
  columns: unset !important;
}

.topic__text p{
  margin:0;
}

.topic__text p+p{
  margin-top: 1rem;
}

.topic__localizationMap{
  padding-top: 0.05px;
  margin-top: 2rem;
  width: calc(100% - 2rem);
  height: 300px;
  margin-left: 1rem;
  border-radius: 1rem;
  border: 5px solid rgba( 255, 255, 255, 0.18 );
  box-shadow: 0 8px 24px 0 rgba( 31, 38, 135, 0.37 );
}

.topic__contact{
  box-shadow: 0 8px 24px 0 rgba( 31, 38, 135, 0.37 );
  margin-bottom: 0 !important;
}

.contact{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  align-items: center;
}

.contact__logo{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contact__logo img{
  min-width: 20%;
  max-width: 20%;
  margin-right: 1rem;
}

.contact__logo p{
  margin: 0;
}

.contact__address{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
}

.contact__address p{
  display:flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.contact__address p > svg {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.contact__address p+p{
  font-size: 0.8rem;
}

.contact__by{
  width: 100%;
  text-align: center;
  background-color: var(--cor-500);
  padding: 1rem 0 1rem 0;
  color: var(--branco);
}



/* DESKTOP CSS */
@media screen and (min-width:768px)
{

  .slide__img1{
    background-position-y: bottom;
  }

  .slide__text_container{
    padding-left:2rem;
    padding-right:2rem;
  }

  .topic__text{
    width: 60%;
    align-self: center;
  }

  .topic__localizationMap{
    align-self: center;
    width: 50%;
  }

  .contact{
    flex-wrap: nowrap;
  }

  .contact__address p+p{
    font-size: 1rem;
  }

}