.pageTabs{
   display: flex;
   width: 100%;
   flex-direction: row;
   flex-wrap: wrap;
   border-bottom: 1px solid hsla(0, 0%, 0%, 0.25);
   margin-top: 1rem;
   padding-bottom: 0.5rem;
   column-gap: .25rem;
}

.pageTabs::after{

}

.pageTabs__tab{
   border: none;
   font-size: 1rem;
   padding: .5rem 2rem;
   background-color: transparent;
   cursor: pointer;
   border-radius: 2rem;
   transition: background-color 200ms ease;
}

.pageTabs__tab:hover{
   background-color: hsla(0, 0%, 87%, 0.267);
}

.pageTabs__tab[aria-current="true"]{
   outline: 1px solid hsla(0, 0%, 47%, 0.568);
}

.pageTabs__tab:focus{
   outline: 2px solid var(--glass_shadow);
}
