
.glassLogin{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width:100%;
   height: 100vh;
   column-gap: 4rem;
   align-self: stretch;
   row-gap: 1rem;
}

.glassLogin__left{
   display: flex;
   flex-basis: auto;
   flex-direction: row;
   align-items: center;
   row-gap: 1rem;
   column-gap: 2rem;
}

.glassLogin__form{
   display: flex;
   width: calc(100vw - 2rem);
   flex-direction: column;
   row-gap: 1rem;
}

.logo_login{
   width: 100px;
}

@media screen and (min-width: 768px){

   .glassLogin{
      flex-direction: row;
   }

   .glassLogin__form{
      width: calc(100vw / 3.5);
   }

}

