@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,400;0,600;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  background: var(--branco);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  scroll-padding-top: 4.8rem;
}

:root
{
  /* COLORS */
  --branco: hsl(258, 100%, 97%);
  --branco-a: hsla(258, 100%, 97%, 15%);
  --amarelo: hsl(37, 96%, 61%);
  --amarelo-in: hsla(37, 96%, 61%, 0.178);
  --cor-200: hsl(140, 93%, 73%);
  --cor-200-a: hsla(140, 93%, 73%, 0.295);
  --cor-300: hsl(140, 83%, 61%);
  --cor-400: hsl(222, 100%, 57%);
  --cor-400-a: hsla(222, 100%, 57%, 10%);
  --cor-500: hsl(222, 100%, 33%);
  --cor-800: hsl(140, 100%, 12%);
  --preto: hsl(0, 0%, 6%);
  --preto-a: hsla(0,0%,6%,15%);
  --preto-a-50: hsla(0,0%,6%,50%);
  --sucesso-out: hsl(120, 93%, 12%);
  --sucesso-in: hsl(120, 74%, 63%);
  --sucesso-msg: hsla(120, 95%, 64%, 0.644);
  --danger-in: hsl(0, 100%, 75%);
  --danger-out: hsl(0, 100%, 14%);

  --bg-pop-msg: hsla(222, 100%, 57%, 40%);
}

* {
   box-sizing: border-box;
   font-family: 'Josefin Sans', sans-serif;
}

 /* SECTION - WIDTHS-HEIGHTS */
 .w-33{
  width: 33%;
}

.w-66{
  width: 66%;
}

.w-100{
  width:100%;
}

.h-100{
  height: 100%;
}

.h-100px{
  height:6.25rem;
}

/* END - WIDTHS-HEIGHTS */

/* SECTION - POSITIONS */
.position-fixed
{
  position: fixed;
}

.position-relative
{
  position: relative;
}

/* SECTION - FLEX */
.d-flex
{
   display:flex;
}

.justify-content-center
{
   justify-content: center;
}

.justify-content-space-between{
   justify-content: space-between;
}

.justify-content-space-evenly
{
  justify-content: space-evenly;
}

.justify_content_end{
  justify-content: flex-end !important;
}

.align-content-start{
   align-content: flex-start;
}

.align-items-center
{
   align-items: center;
}

.align_self_center{
  align-self: center !important;
}

.flex-direction-row{
   flex-direction: row !important;
}

.flex-direction-row-reverse{
   flex-direction: row-reverse !important;
}

.flex-direction-column{
   flex-direction: column;
}

.flex-no-wrap{
   flex-wrap: nowrap;
}

.flex-wrap{
   flex-wrap: wrap;
}

.column-gap-05rem{
  column-gap: 0.5rem;
}

.column-gap-1rem{
   column-gap: 1rem;
}

.row-gap-1rem{
   row-gap: 1rem;
}

/* END - FLEX*/

/* SECTION - MARGINS */
.m-inline-10{
  margin-left:10%;
  margin-right: 10%;
}

.m-top-0{
  margin-top: 0px !important;
}

.m-top-05rem{
  margin-top: 0.5rem;
}

.m_bottom_0{
  margin-bottom: 0px !important;
}

.m-bottom-05rem{
  margin-bottom: 0.25rem;
}

.m-y-auto{
  margin-top:auto;
  margin-bottom: auto;
}

.m-left-10{
  margin-left:10%;
}

.m-right-10{
  margin-right:10%;
}

/* SECTION - PADDINGS*/

.p-inline-10{
  padding-left: 10%;
  padding-right:10%;
}

.p-top-5
{
  padding-top: 5%;
}

.p-top-10
{
  padding-top: 10%;
}

.p-left-5
{
  padding-left: 5%;
}

.p-right-5
{
  padding-right: 5%;
}

.p-bottom-5
{
  padding-bottom: 5%;
}

.p-bottom-10
{
  padding-bottom: 10%;
}

/* SECTION - FONTS */

.font-weight-100
{
   font-weight: 100;
}

.font-weight-200
{
   font-weight: 200;
}

.font-weight-300
{
   font-weight: 300;
}

.font-weight-400
{
   font-weight: 400;
}

.font-weight-500
{
   font-weight: 500;
}

.font-weight-600
{
   font-weight: 600;
}

.font-weight-700
{
   font-weight: 700;
}

.font-weight-800
{
   font-weight: 800;
}

.font-weight-900
{
   font-weight: 900;
}

.text-align-center{
  text-align: center !important;
}