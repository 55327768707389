

.glass__logo{
   color: var(--preto);
   font-weight: 900;
   pointer-events: none;
   user-select: none;
}

.glass__logo p{
   margin: 0;
}

.glass__logo p:first-of-type{
   font-size: 2rem;
   line-height: 1rem;
   padding-top: .5rem;
   outline: 2px solid var(--preto);
   outline-offset: 1px;
   width: fit-content;
}

.glass__logo p:nth-child(2){
   font-size: 1.15rem;
   line-height: .6rem;
   padding-top: .6rem;
   text-align: center;
}

.glass__logo span{
   display: block;
   font-size: .35rem;
   text-align: right;
   padding-top: 0.25rem;
   font-style: italic;
}
