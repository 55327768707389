.page__margin_menu{
   --menu_top_height: 4.8rem;

   margin-top: var(--menu_top_height);
   display: flow-root;
}

.page .page__container{
   min-height: calc(100vh - var(--menu_top_height));
}

.page__container{
}

.pages{
   padding:0 1rem;
   text-align: justify;
}

.page__header{
}

.page__header h1{
   margin: 0;
   padding: 1.5rem 1rem;
   background-repeat: repeat;
   background-size: 50%;
   /* background-image: url("/src/assets/imgs/background_pattern_1.svg"); */
}

.page__path{
   display: flex;
   width: 100%;
   flex-direction: row;
   flex-wrap: wrap;
   padding: 0.75rem 1rem;
   background-color: var(--cor-400-a);
   column-gap: 0.25rem;
}

.page__path a{
   color: var(--cor-500);
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{
   .page .page__container{
      min-height: calc(100vh - 4rem - 2rem);
   }

   .pages p{
      -webkit-column-gap: 2rem;
      column-gap: 2rem;
      -webkit-columns: 300px 2;
      columns: 300px 2;
      line-height: 1.75rem;
      text-align: justify;
   }

   .page__container_pages{
      margin: 1rem;
      width: 80% !important;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--branco);
      box-shadow: 0 0 10px 0px var(--preto-a);
      border-radius: 1rem;
      overflow: hidden;
   }
   .page__header h1{
      background-image: none;
   }
   .page__background_pattern{
      background-repeat: repeat;
      background-size: 20%;
      /* background-image: url("/src/assets/imgs/background_pattern_1.svg"); */
   }
}