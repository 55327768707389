.pageGalerie{
   padding:1rem;
   padding-top: 2rem;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
}

.pageGalerie__title{
   display: flex;
   flex-direction: column;
   align-items: center;
   color: var(--cor-800);
   margin-top:2rem;
   margin-bottom: 2rem;
   row-gap: 1rem;
}

.pageGalerie__title h2{
   margin: 0;
   font-size: 1.5rem;
}

.pageGalerie__title > h2 > svg{
   font-size: 3rem;
   margin-bottom: -3px;
}

.pageGalerie__search{
   width: 100%;
   display: flex;
   align-items: center;
   background-color: var(--branco);
}

.pageGalerie__search input{
   width: calc(100% - 2rem);
   background:none;
   border: none;
   padding: .5rem 0 .5rem .75rem;
   font-size: 1.25rem;
   border: 1px solid var(--cor-400-a);
}

.pageGalerie__search svg{
   font-size: 2.5rem;
   justify-self: flex-end;
}


.pageGalerie__block{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   border:1px solid var(--cor-400-a);
   border-bottom: none;
   padding: 1.25rem 0;
}

.pageGalerie__block:last-child{
   border:1px solid var(--cor-400-a);
}

.pageGalerie__block:hover{
   background-color: var(--cor-400-a);
}

.pageGalerie__block a{
   display: flex;
   text-decoration: none;
   justify-content: center;
   align-items: center;
}

.pageGalerie__block_info{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   row-gap: 0.5rem;
   width: 50%;
   padding:1rem;
   text-align: left;
   font-size: 1.1rem;
}

.pageGalerie__block_info span{
   font-size: .75rem;
}

.pageGalerie__block_download{
   width: 50%;
   font-size: 1.1rem;
   column-gap: .5rem;
}

.pageGalerie__block_download svg{
   font-size: 2rem;
}

.pageGalerie__galerie{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   column-gap: 1rem;
   row-gap: 1rem;
}

.photoGalerie__photo{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: calc(50% - 0.5rem);
   aspect-ratio: 6/4;
   overflow: hidden;
   transition: transform 200ms;
   position: relative;
}

.photoGalerie__photo img{
   width: 100%;
   object-fit: fill;
}

.photoGalerie__photo span{
   display: block;
   width: 100%;
   position: absolute;
   text-align: center;
   bottom: 0;
   background-color: var(--preto-a-50);
   padding:0.5rem 1rem;
   color: var(--branco);
   transition: transform 200ms;
   pointer-events: none;
   font-size: 3vw;
}

.photoGalerie__photo span > i{
   font-family: 'Times New Roman', Times, serif;
   font-style: italic;
}

.photoGalerie__photo:hover{
   transform: scale(1.05);
}

.photoGalerie__photo:hover span{
   transform: translateX(0);
}

.loading{
   position: absolute;
   width: 100%;
   height: 100%;
}

.loading__gradient{
   animation-duration: 3s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: #f6f7f8;
   background: linear-gradient(to right, hsl(0, 0%, 92%) 8%, hsl(0, 0%, 90%) 38%, hsl(0, 0%, 92%) 54%);
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{

   .pageGalerie__title{
      flex-direction: row;
      justify-content: space-between;
   }

   .pageGalerie__search{
      width: calc(100% / 3);
   }

   .pageGalerie__search input{
      font-size: 1rem;
   }

   .pageGalerie__block_info{
      padding: 0;
      width: calc(100% / 3 * 2);
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
   }

   .pageGalerie__block_info span{
      font-size: 1rem;
   }

   .pageGalerie__block_download{
      width: calc(100% / 3 * 1);
   }

   .pageGalerie__block{
      padding: .75rem 0;
   }

   .pageGalerie__galerie{
      display: flex;
      justify-content: center;
      overflow: hidden;
   }

   .pageGalerie__galerie .image-gallery{
      align-self: center;
      width: 75%;
   }

   .pageGalerie__galerie .image-gallery-bullets-container button{
      margin-bottom: 0.5rem;
   }

   .photoGalerie__photo{
      width: calc(25% - (1rem));
      cursor: pointer;
   }

   
   .photoGalerie__photo span{
      transform: translateY(100%);
      font-size: 1rem;
   }

}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}