.videoGalerie__youtube{
   position: relative;
   width: 100%;
   height: calc(50vw);
}

/* DESKTOP CSS */
@media screen and (min-width:768px)
{
   .videoGalerie__youtube{
      position: relative;
      width: calc(50% - 1rem);
      height: calc(50vw * .42);
   }
}